import React from 'react'

const About = () => {
	return <>
	
	<section className="success" id="about">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 text-center">
                <h2>About</h2>
                <hr className="star-light" />
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <p>We are a professional privately run cleaning company specializing in domestic and commercial cleaning throughout London. We have extensive experience with fully insured cleaners. Our focus is on providing thorough and quality cleaning.</p>
            </div>
        </div>
    </div>
</section>

	
	</>
}

export default About