import React from "react";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Hero from "./component/Hero";
import About from "./component/About";
import Portfolio from "./component/Portfolio";
import Modals from "./component/Modals";
import Contact from "./component/Contact";

const Home = () => {

  return (
    <>
      <div className="border-white-large">
        <div className="border-green-min">
          <div className="border-white-min">
            <div className="border-green-large">
              <div className="border-white-min">
                <div className="border-green-min">
                  <div className="border-white-large">
                    <Header />
                    <Hero />
                    <Portfolio />
                    <About />
                    <Contact/>
                    <Modals />
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
