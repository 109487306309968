import React from "react";

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header page-scroll">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand" href="#page-top">
              Gina's Cleaning Services
            </a>
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li className="hidden">
                <a href="#page-top">asd</a>
              </li>
              <li className="page-scroll">
                <a href="#page-top">Home</a>
              </li>
              <li className="page-scroll">
                <a href="#portfolio">Services</a>
              </li>
              <li className="page-scroll">
                <a href="#about">About</a>
              </li>
              <li className="page-scroll">
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div
        title="Scroll to top"
        class="scroll-top page-scroll visible-xs visible-sm"
      >
        <a class="btn btn-primary" href="#page-top" title="Scroll to Top">
          <i class="fa fa-chevron-up"></i>
        </a>
      </div>
    </>
  );
};

export default Header;
