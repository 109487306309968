import React, { useState, useCallback } from "react";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    postcode: "",
    message: "",
  });
  const [formDisabled, setFormDisabled] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState("");

  const { name, email, phoneNumber, postcode, message } = formData;

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    },
    [setFormData]
  );

  const handleVerify = useCallback(
    (token) => {
      setRecaptchaValue(token);
    },
    [setRecaptchaValue]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA.");
      return;
    }

    try {
      const response = await fetch("/api/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, recaptchaValue }),
      });

      if (response.ok) {
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          postcode: "",
          message: "",
        });
        setFormDisabled(true);
        setMessageSent(true);
      } else {
        console.log("Failed to send message");
      }
    } catch (err) {
      console.error(err);
      console.log("An error occurred");
    }
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2>Contact</h2>
              <hr className="star-primary" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-lg-offset-2">
              <form id="contactForm" onSubmit={handleSubmit}>
                <div className="row control-group">
                  <div className="form-group col-xs-12 floating-label-form-group controls">
                    <label htmlFor="nameInput">Name</label>
                    <input
                      id="nameInput"
                      autocomplete="name"
                      type="text"
                      placeholder="Your Name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      required
                      disabled={formDisabled}
                    />
                  </div>
                </div>

                <div className="row control-group">
                  <div className="form-group col-xs-12 floating-label-form-group controls">
                    <label htmlFor="emailInput">Email</label>
                    <input
                      id="emailInput"
                      autocomplete="email"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      required
                      disabled={formDisabled}
                    />
                  </div>
                </div>

                <div className="row control-group">
                  <div className="form-group col-xs-12 floating-label-form-group controls">
                    <label htmlFor="phoneNumberInput">Phone Number</label>
                    <input
                      id="phoneNumberInput"
                      autocomplete="tel"
                      type="tel"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handleChange}
                      required
                      disabled={formDisabled}
                    />
                  </div>
                </div>

                <div className="row control-group">
                  <div className="form-group col-xs-12 floating-label-form-group controls">
                    <label htmlFor="postcodeInput">Postcode</label>
                    <input
                      id="postcodeInput"
                      autocomplete="postal-code"
                      type="text"
                      placeholder="Postcode"
                      name="postcode"
                      value={postcode}
                      onChange={handleChange}
                      required
                      disabled={formDisabled}
                    />
                  </div>
                </div>

                <div className="row control-group">
                  <div className="form-group col-xs-12 floating-label-form-group controls">
                    <label htmlFor="messageInput">Message</label>
                    <textarea
                      id="messageInput"
                      rows={3} // Set minimum rows to 3
                      autocomplete="off"
                      placeholder="Message"
                      name="message"
                      value={message}
                      onChange={handleChange}
                      required
                      disabled={formDisabled}
                      style={{ resize: "none", margin: "auto" }} // Disable resizing
                    />
                  </div>
                </div>

                <GoogleReCaptcha
                  title="reCAPTCHA Response"
                  placeholder="reCAPTCHA Response"
                  id="g-recaptcha-response"
                  name="g-recaptcha-response"
                  className="g-recaptcha-response"
                  onVerify={handleVerify}
                  aria-label="Captcha"
                />

                <div className="row">
                  <br />
                  <div className="form-group col-xs-12">
                    <button
                      className="btn btn-success btn-lg"
                      type="submit"
                      disabled={formDisabled}
                    >
                      Send
                    </button>
                  </div>
                </div>

                {messageSent && (
                  <div>
                    <div id="success">
                      <p>Message is sent!</p>
                      <p>
                        Thank you for contacting us. We will get back to you
                        shortly.
                      </p>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </GoogleReCaptchaProvider>
  );
};

export default Contact;
