import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './Home'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Error from './Error';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: "/*/", // Match any URL with a trailing slash
    element: <Error />, // Redirect to Error component
  },
  {
    path: "*", // Catch-all route
    element: <Error />, // Render ErrorPage for unmatched URLs
  },
  {
    path: "**", // Catch-all route
    element: <Error />, // Render ErrorPage for unmatched URLs
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <script src="/js/jquery.js" onload="loadNextScripts()"></script>
      </Helmet>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
);

// Define loadNextScripts globally
window.loadNextScripts = function () {
  // Function to load other scripts after jQuery is loaded
  const scripts = [
    "/js/bootstrap.min.js",
    "https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3/jquery.easing.min.js",
    "/js/classie.js",
    "/js/cbpAnimatedHeader.js",
    "/js/jqBootstrapValidation.js",
    "/js/design.js"
  ];

  loadScript(0);

  function loadScript(index) {
    if (index < scripts.length) {
      const script = document.createElement('script');
      script.src = scripts[index];
      script.onload = () => loadScript(index + 1);
      document.body.appendChild(script);
    }
  }
};



