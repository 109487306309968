import React from 'react'

const Hero = () => {
	return <>
	
    <header>
        <div className="container">
          <img className="img-responsive logo" src="img/logo.jpg" alt="logo" />
          <h3>Gina's Domestic and Commercial<br />Cleaning Services</h3>
        </div>
      </header>

	
	</>
}

export default Hero