import React from 'react';

const Modals = () => {
    return (
        <>
            <div className="portfolio-modal modal fade" id="portfolioModal1" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>Household Cleaning</h2>
                                    <hr className="star-primary" />
                                    <img src="img/portfolio/household.jpg" className="img-responsive img-centered" alt="" />
                                    <p>Whether you need a deep clean of your house or flat, or a weekly or fortnightly cleaning service we are able to offer the flexibility to you. <br /> If you are a landlord, then we have extensive experience of getting residential properties cleaned and ready to let again. <br /> Please contact us for a quote for the particular service you require. We have a large number of happy domestic clients so be rest assured you are in good hands.</p>
                                    <br />
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal2" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>Business Cleaning</h2>
                                    <hr className="star-primary" />
                                    <img src="img/portfolio/business.jpg" className="img-responsive img-centered" alt="" />
                                    <p>We offer one off and regular cleaning services for your office or retail premises. We understand that sometimes due to working hours you require cleaning staff during out of working hours. This is no issue and we would be happy to discuss this with you. <br /> Please contact us for a quote for the particular service you require. We have a range of commercial and retail property clients that are very happy with our service so be rest assured you are in good hands.</p>
                                    <br />
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal3" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>Ironing Services</h2>
                                    <hr className="star-primary" />
                                    <img src="img/portfolio/ironing.jpg" className="img-responsive img-centered" alt="" />
                                    <p>We also offer ironing services. We understand that people have less time during their busy work schedules, so let us do your ironing for you. We can also combine it with our cleaning service at your property. Please contact us for a quote for ironing.</p>
                                    <br />
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal4" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>Declutter</h2>
                                    <hr className="star-primary" />
                                    <img src="img/portfolio/declutter.jpg" className="img-responsive img-centered" alt="" />
                                    <p>Running out of space? Let us help you declutter your house or flat and get everything organised. We offer this service due to a number of customers asking us for help in this area. Please contact us to talk through what needs to be done, we will visit and provide you with a quote for the work.</p>
                                    <br />
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal5" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>Running Errands</h2>
                                    <hr className="star-primary" />
                                    <img src="img/portfolio/errands.jpg" className="img-responsive img-centered" alt="" />
                                    <p>Over the past few years some of our customers have asked us to run some errands and depending on the work we are very happy to do this. For example, taking clothes to the charity shop, food shopping, taking garments to the dry cleaners.</p>
                                    <br />
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal6" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>Small Gardening</h2>
                                    <hr className="star-primary" />
                                    <img src="img/portfolio/gardening.jpg" className="img-responsive img-centered" alt="" />
                                    <p>Is the garden a little out of control? Or just needs a quick tidy up? We have experience in sorting out our customer’s small gardens. Please contact us to discuss and we will be able to visit and provide a quote.</p>
                                    <br />
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal7" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>Helping Elderly</h2>
                                    <hr className="star-primary" />
                                    <img src="img/portfolio/elderly.jpg" className="img-responsive img-centered" alt="" />
                                    <p>In the past we have helped some of our elderly clients. We can provide cooking, accompany to doctor’s appointment, carry out shopping. Please contact us and we can discuss what you require.</p>
                                    <br />
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal8" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>Looking after Pets</h2>
                                    <hr className="star-primary" />
                                    <img src="img/portfolio/pets.jpg" className="img-responsive img-centered" alt="" />
                                    <p>We are able to feed your pets if you are away. We can also carry out dog walking. Please contact us and we can discuss your requirements.</p>
                                    <br />
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal9" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>Gift Vouchers</h2>
                                    <hr className="star-primary" />
                                    <img src="img/portfolio/supplies.jpg" className="img-responsive img-centered" alt="" />
                                    <p>Why not purchase one of our gift vouchers as a present for a family member or friend? You could purchase one of our cleaning gift vouchers which for a spring clean of someone’s house or flat. Contact us to find out more</p>
                                    <br />
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modals;
