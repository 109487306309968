import React from 'react'

const Footer = () => {
	return <>

        <footer className="text-center">
            <div className="footer-above">
                <div className="container">
                    <div className="row">
                        <div className="footer-col">
                            <h3>Call us</h3>
                            <p>Free: 0800 619 06 15</p>
                            <p>Mobile: 07724 282924</p>
                        </div>
                        <div className="footer-col">
                            <h3>Email</h3>
                            <p>ginascleaningser@gmail.com</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-below">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            Copyright &copy; {new Date().getFullYear()} Gina's Cleaning Services
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  


							
         
	</>
}

export default Footer