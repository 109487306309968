import React from 'react';

const Error = () => {
  // Perform the redirection
  React.useEffect(() => {
    window.location.href = '/'; // Replace 'https://example.com' with your desired domain
  }, []);

  // Placeholder UI while redirecting
  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};

export default Error;
